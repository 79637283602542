import React from 'react';
import ResumeDownload from './ResumeDownload';

export interface ExperienceProps {}

const Experience: React.FC<ExperienceProps> = (props) => {
    return (
        <div className="site-page-content">
            <ResumeDownload />
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>"Man in the Mirror"</h1>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={'https://en.wikipedia.org/wiki/Bad_(album)'}
                        >
                            <h4>BAD</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Michael Jackson</h3>
                        <b>
                            <p>August 31, 1987</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                当我们目睹社会中的不幸、悲惨与不公时，若想让世界变得更美好，若希望带来改变，我们应当从镜中的那个人开始，也就是从自身做起。
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                        I'm starting with the man in the mirror

                        </p>
                    </li>
                    <li>
                        <p>
                        I'm asking him to change his ways

                        </p>
                    </li>
                    <li>
                        <p>
                        And no message could've been any clearer

                        </p>
                    </li>
                    <li>
                        <p>
                        If they wanna make the world a better place

                        </p>
                    </li>
                    <li>
                        <p>
                        Take a look at yourself and then make a change

                        </p>
                    </li>
                </ul>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>"Heal the World"</h1>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://en.wikipedia.org/wiki/Dangerous_(Michael_Jackson_album)'}
                        >
                            <h4>Dangerous</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Michael Jackson</h3>
                        <b>
                            <p>November 23, 1992</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                也许你会感到周围是充满爱心的，感到改善这个世界的任务是重大的。我想只有世界没有了战争，儿童才是幸福的，地球才是美丽的。
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                        Heal the world, make it a better place

                        </p>
                    </li>
                    <li>
                        <p>
                        For you and for me and the entire human race

                        </p>
                    </li>
                    <li>
                        <p>
                        There are people dying

                        </p>
                    </li>
                    <li>
                        <p>
                        If you care enough for the living

                        </p>
                    </li>
                    <li>
                        <p>
                        Make a better place for you and for me

                        </p>
                    </li>
                </ul>
            </div>

        </div>
    );
};

const styles: StyleSheetCSS = {
    header: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    skillRow: {
        flex: 1,
        justifyContent: 'space-between',
    },
    skillName: {
        minWidth: 56,
    },
    skill: {
        flex: 1,
        padding: 8,
        alignItems: 'center',
    },
    progressBar: {
        flex: 1,
        background: 'red',
        marginLeft: 8,
        height: 8,
    },
    hoverLogo: {
        height: 32,
        marginBottom: 16,
    },
    headerContainer: {
        alignItems: 'flex-end',
        width: '100%',
        justifyContent: 'center',
    },
    hoverText: {
        marginBottom: 8,
    },
    indent: {
        marginLeft: 24,
    },
    headerRow: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export default Experience;
