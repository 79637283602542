import React from 'react';

import girlRun from '../../../assets/pictures/projects/art/girl-run.gif';
import chen1 from '../../../assets/pictures/projects/art/laochen1.jpg';
import chen2 from '../../../assets/pictures/projects/art/laochen2.jpg';
import chen3 from '../../../assets/pictures/projects/art/laochen3.jpg';
export interface ArtProjectsProps {}

const ArtProjects: React.FC<ArtProjectsProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>Art & Design</h1>
            <h3>正在努力...</h3>
            <br />
            <div className="text-block">
                <p>
                在我高中时期，我的班主任姓陈。他是一位非常关心学生的教育者，对我们的学业和生活倾注了大量心血。然而，这种关心有时表现得过于热切，导致了对自由时间的一些限制。                </p>
                <br />
                <p>
                尽管如此，我深受班主任的关怀所感动，同时也感受到了一些束缚。为了表达我对他的敬意，我决定根据他的肖像创作了三幅画作。这些画成为了我创作中的重要作品，我至今认为无人可以超越。每一幅画都承载了我对这位班主任的感激之情，也成为了我对那段时光的珍贵回忆。
                </p>
            </div>
            <div className="text-block">
                <h2>老陈第一版</h2>
                <br />
                <p>

                第一张画我极为注重细节，尽力用我那尚显稚嫩的画技，描绘出班主任陈老师上课的情景。我生动地捕捉了他讲授立体几何的瞬间，尽可能通过画笔还原他在黑板前的神情和动作。每一笔每一画都是对他教学瞬间的致敬。虽然我的画工还不成熟，但我竭尽全力去捕捉那一刻的细腻表情和姿态。                </p>
                <br />
                <div className="captioned-image">
                    <img src={chen1} alt="" />
                    <p>
                        <sub>
                            <b>图 1: </b> 老陈第一版
                        </sub>
                    </p>
                </div>
                </div>
                <div className="text-block">
                <h2>老陈第二版</h2>
                <br />
                <p>

                
在第二张画中，我选择了一种更为抽象的风格，试图捕捉班主任陈老师沧桑的面容以及他独特的表情和神态。通过这种抽象的表现方式，我想突出陈老师作为教育者所经历的风雨，以及岁月在他脸上留下的痕迹。                </p>
                <br />
                <div className="captioned-image">
                    <img src={chen2} alt="" />
                    <p>
                        <sub>
                            <b>图 2: </b> 老陈第二版
                        </sub>
                    </p>
                </div>
                <div className="text-block">
                <h2>老陈第三版</h2>
                <br />
                <p>
                在第三张画中，我采用了现代抽象派的风格，将前两种画法的经验巧妙结合。这幅画着重表现了班主任陈老师训话时的严厉面容，展示了作为一位教育者的严肃和责任感。痕迹。                </p>
                <br />
                <div className="captioned-image">
                    <img src={chen3} alt="" />
                    <p>
                        <sub>
                            <b>图 3: </b> 老陈第三版
                        </sub>
                    </p>
                    </div>
                <br />
                <p>
                我将继续投身于艺术创作的旅程。然而，坦率地说，我对自己创作的“三画老陈”系列感到十分自豪，认为它们是难以超越的作品。虽然玩乐是生活的一部分，但我对班主任陈老师怀有极大的敬意。他的教诲、关爱以及对学生的付出，都在我心中留下了深刻的印记。                </p>
                <br />
                </div>
                {/* <h3> Screen record time-lapses and make gifs</h3> */}
            </div>
        </div>
    
    );
};

export default ArtProjects;
