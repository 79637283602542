import React from 'react';

import foxcoandherwork from '../../assets/pictures/foxco-and-her-work.jpg';
import myavatar from '../../assets/pictures/oldtouxiang.jpg';
import menodeseek from '../../assets/pictures/menodeseek.jpg';

import me from '../../assets/pictures/workingAtComputer.jpg';
import meNow from '../../assets/pictures/currentme.jpg';
import { Link } from 'react-router-dom';
import ResumeDownload from './ResumeDownload';

export interface AboutProps {}

const About: React.FC<AboutProps> = (props) => {
    return (
        // add on resize listener
        <div className="site-page-content">
            {/* <img src={me} style={styles.topImage} alt="" /> */}
            <h1 style={{ marginLeft: -16 }}>Welcome</h1>
            <h3>I'm FOXCOO.</h3>
            <br />
            <div className="text-block">
                <p>
                当网络的浪潮涌起时，我总是在浪尖上乘风破浪，探索无尽的可能性。你好，我是一位对网络充满热情的探索者，时刻追寻新奇的事物，享受每一次挑战与成长的过程。我对主机有着独特的兴趣，每一台主机都如同一片未曾踏足的领域，激发着我不断去折腾和探索。我也是一名HIFI爱好者，沉浸在音乐的海洋里，细细品味每一个音符的美妙。
                </p>
                <br />
                <p>
                更让我痴迷的是电子游戏。作为一名忠实的游戏爱好者，我的梦想是成为一名游戏制作人，用我的创意与激情，打造出让人无法忘怀的游戏世界。
                </p>
                <br /> 
                <p>
                我活跃在HostLoc、NodeSeek、Telegram、Discord等平台，分享技术、交流经验，并结识志同道合的朋友。网络是我展示自己、分享故事的舞台，而成为游戏开发者则是我最热切的目标。
                </p>
                <br />
                <p>
                感谢您抽出时间查看我的主页。如果您有任何问题与意见，请通过{' '}
                    <Link to="/contact">此页 </Link>联系我。{' '}
                </p>
            </div>
            <ResumeDownload />
            <div className="text-block">
                <h3>🔷 关于 “FOXCOO”</h3>
                <br />
                <br />
                <p>
                关于我的ID “FOXCOO”的起源，源自几年前我追看日本综艺《双层公寓》2019-2020东京篇时的一个特别瞬间。节目中的女孩渡边香织（Kaori Watanabe），她的独特个性深深吸引了我，她的ID是“foxco”。当我为自己创造ID时，决定在她的基础上多加一个“O”，形成了“FOXCOO”。
                </p>
                <br />
                <p>
                为什么加这个额外的“O”呢？对我来说，这代表着“Obsession”（痴迷），象征着我对渡边香织的欣赏和由她引发的独特情感。这不仅是对她个性的一种敬仰，也表达了我对爱情和美好事物的追求。这个名字从那时起便伴随我左右，成为我网络世界中的象征，同时承载着一段美好回忆和情感的寄托。
                </p>
                <br />
                <p>
                通过这个取名方式，我赋予了自己的ID更多个性，也让它成为一种情感与回忆的延续。   
                </p>
                <br />
                <div className="captioned-image">
                    <img src={foxcoandherwork} style={styles.image} alt="" />
                    <p>
                        <sub>
                            <b>图 1:  </b>foxco 和她的作品 :)
                        </sub>
                    </p>
                </div>
                <h3>🔷 关于头像</h3>
                <br />
                <br />
                <p>
                关于我的头像，源自我对迈克尔·杰克逊（MJ）的深深崇拜。我一直希望能够将自己的形象与他的经典装扮和风格保持一致，尤其是在头像这样能直观展现个性的地方，我格外用心。
                </p>
                <br />
                <p>
                由于我个人的绘画能力有限，无法亲手创作出理想的头像，于是我开始在网上寻找富有创意的艺术家。最终，我找到了
                {''}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.deviantart.com/michiiejackson"
                    >
                        这位艺术家的主页
                    </a>
                    。
                    </p>
                <br />
                <p>
                    TA专注于迈克尔·杰克逊相关作品，我对TA的作品一见倾心。经过一番挑选，我选定了现在的头像。
                    </p>
                <br />
                <div className="captioned-image">
                    <img src={myavatar} style={styles.image} alt="" />
                    <p>
                        <sub>
                            <b>图 2:  </b>TA绘制的作品
                        </sub>
                    </p>
                </div>
                <p>
                    这些年在网络世界里，我也尝试搭建了属于自己的{''}
                    <Link to="/projects/software">网站和项目。</Link> 随着时间的推移，我逐渐形成了自己的想法与设计理念。
                    </p>
                <br />
                <p>
                    虽然自主设计网站的过程充满挑战，但每一次尝试与改进，都带来了巨大的成就感，也推动了我技术的提升。我相信，通过不断学习和实践，我终将能够完全独立编写和呈现属于自己的项目，实现真正的个人风格和技术表达。
                </p>
                <br />
                <br />
                <br />
                <div style={{}}>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'justify',
                            alignSelf: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <h3>🔷 关于业余爱好</h3>
                        <br />
                        <br />
                        <p>
                        除了这些，我还有许多其他的爱好。其中之一就是音乐创作。我热衷于用音乐来表达内心的情感和想法，这也是我探索世界的另一种方式。如果你感兴趣，可以访问我的{' '}
                            <Link to="/projects/music">音乐</Link>{' '}
                            主页。在那里你可以找到我一些初步尝试的音乐作品。虽然这些作品还很稚嫩，但每一首都倾注了我的心血和热情。
                            </p>
                        <br />
                        <p>    
                        与此同时，我也在尝试使用 iPad 进行艺术创作。通过数字绘画，我能够以更直观、自由的方式表达我的想法和情感。目前，我的目标之一是在 iPad 上创作出更多艺术作品，特别是希望有一天能创作出符合自己风格的迈克尔·杰克逊（MJ）主题作品。虽然现在我的{' '}
                            <Link to="/projects/music">艺术</Link>{' '}作品还寥寥无几，但我相信随着时间的推移，这个数字会不断增加。
                        </p>
                        <br />
                        <p>
                        此外，我目前在 NodeSeek 担任交易中介的职务。我要由衷地感谢 NodeSeek 的管理层以及广大用户对我的信任和支持，这对我来说是巨大的鼓舞和认可。
                        </p>
                        <br />
                        <p>在这个角色中，我将继续不懈努力，致力于完善中介机制，确保每一位用户的交易体验都安全可靠。</p>
                    </div>
                    <div style={styles.verticalImage}>
                        <img src={menodeseek} style={styles.image} alt="" />
                        <p>
                            <sub>
                                <b>图 3:</b> 来自 NodeSeek的交易中介认证信息
                            </sub>
                        </p>
                    </div>
                </div>
                <br />
                <br />
                <p>感谢您抽出时间来了解我！
                </p>
                <br />
                <p>
                如果您有任何问题或意见，我很乐意听取 。您可以通过{' '}
                    <Link to="/contact">这个页面 </Link>找到我的联系方式。
                </p>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    contentHeader: {
        marginBottom: 16,
        fontSize: 48,
    },
    image: {
        height: 'auto',
        width: '100%',
    },
    topImage: {
        height: 'auto',
        width: '100%',
        marginBottom: 32,
    },
    verticalImage: {
        alignSelf: 'center',
        // width: '80%',
        marginLeft: 32,
        flex: 0.8,

        alignItems: 'center',
        // marginBottom: 32,
        textAlign: 'center',
        flexDirection: 'column',
    },
};

export default About;
