import React, { useState } from 'react';

// @ts-ignore
import fengbao from '../../../assets/audio/fengbao.flac';

import wangyiyun from '../../../assets/pictures/projects/audio/wangyiyun.jpg';
import { MusicPlayer } from '../../general';

export interface MusicProjectsProps {}

const MusicProjects: React.FC<MusicProjectsProps> = (props) => {
    const [currentSong, setCurrentSong] = useState<string>('');

    return (
        <div className="site-page-content">
            <h1>Music & Sound</h1>
            <h3>最有纪念意义的一首</h3>
            <br />
            <div className="text-block">
                <p>
                从小我便被音乐的魅力深深吸引。最初，我接触的是二胡，这成为了我音乐之旅的起点。随着年龄的增长，我开始使用FL Studio进行音乐创作，迈入了电子音乐的世界。回想起来，二胡与FL Studio之间形成了有趣的对比，令人忍俊不禁。
                </p>
                <br />
            </div>
            <h2>发布到网易云</h2>
            <br />
            <p>
            我清楚记得，那是我创作的第一首自认为尚算能听的音乐。创作过程充满了激情，我决定将它分享给更多人，于是将这首作品发布在了网易云音乐上。那一刻，我的心情无比激动和自豪，因为我终于第一次将自己的音乐呈现给大家。
            </p>
            <br />
            <div className="captioned-image">
                    <img src={wangyiyun}  alt="" />
                    <p>
                        <sub>
                            <b>图 1:  </b>网易云音乐人
                        </sub>
                    </p>
                </div>
            <br />

            <MusicPlayer
                src={fengbao}
                title="风暴"
                subtitle="FOXCOO - 2022"
                currentSong={currentSong}
                setCurrentSong={setCurrentSong}
            />

            <br />
            <br />
            <h2>GAPCOE?</h2>
            <br />
            <p>
            在网易云音乐上，你也许会看到我的另一个ID——GAPCOE。没错，这也是我。这个名字源于我初中时期，受到初恋的影响而起。GAPCOE不仅仅是一个网络ID，更是我青涩岁月的美好回忆。在初中时代，音乐成为我表达感情的一种方式，而GAPCOE成为了我音乐创作的象征。
            <br />
            <br />
            尽管现在我在网络上的ID可能有所不同，但我一直珍藏着GAPCOE这个身份，希望未来能够在合适的时机，用这个名字来推动我的音乐事业，分享更多的音乐故事。GAPCOE承载了我珍贵的回忆和情感，我期待着有一天能够用它传递更多的美好，让更多人共同欣赏这份情感。
            </p>
            <br />
        </div>
    );
};

// const styles: StyleSheetCSS = {};

export default MusicProjects;
