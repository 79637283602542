import React from 'react';


// @ts-ignore
import hosteyev1 from '../../../assets/pictures/projects/software/hosteyev1.mp4';
// @ts-ignore
import hosteyev2 from '../../../assets/pictures/projects/software/hosteyev2.mp4';
// @ts-ignore
import hosteyechatbot from '../../../assets/pictures/projects/software/hosteyebot.mp4';
// @ts-ignore
import foxcooemby from '../../../assets/pictures/projects/software/foxcooemby.mp4';
// @ts-ignore
import imgeye from '../../../assets/pictures/projects/software/imgeye.mp4';

import ResumeDownload from '../ResumeDownload';
import VideoAsset from '../../general/VideoAsset';

export interface SoftwareProjectsProps {}

const SoftwareProjects: React.FC<SoftwareProjectsProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>Projects</h1>
            <h3>Projects</h3>
            <br />
            <p>
            这些年来，我一直对探索和搭建各种开源服务充满热情，以满足个性化需求、保护隐私，并更好地管理我的数字生活。搭建和维护这些服务的过程，已成为我在技术领域中不断学习和成长的重要组成部分。
            </p>
            <br />
            <ResumeDownload />
            <br />
            <div className="text-block">
                <h2>HOSTEYE.NET</h2>
                <br />
                <p>
                最初，我创建 hosteye.net 是为了分享自己在各种科技折腾中的经验和教训，同时也充分利用服务器资源，避免闲置。随着时间的推移，网站逐渐演变为一个更加多元化的平台。
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={hosteyev1} />
                    <p style={styles.caption}>
                        <sub>
                            <b>图 1:</b> HOSTEYE v1.0
                        </sub>
                    </p>
                </div>
                <p>
                如今，我主要发布与我作为交易中介相关的信息，这使得网站的定位变得有些模糊。HOSTEYE 既是一个技术分享的平台，又是一个发布交易信息的地方。这种变化源于我对网站内容的逐步调整，目的是更好地满足我的多元化需求。无论是科技探索还是交易信息分享，这个网站已成为一个多功能平台。
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={hosteyev2} />
                    <p style={styles.caption}>
                        <sub>
                            <b>图 2:</b> HOSTEYE v2.0
                        </sub>
                    </p>
                </div>
                
                <br />
                <br />
                <h3>例如:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://hosteye.net/jiao-yi-zhong-jie/"
                        >
                            <p>
                                <b>[交易中介]</b> - 这里是中介服务信息的总览
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://hosteye.net/jishi-gallery/"
                        >
                            <p>
                                <b>[交易鸡市]</b> - 方便交易的专属“交易鸡市”平台
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://hosteye.net/yan-jian-tou-gao/"
                        >
                            <p>
                                <b>[《眼见》]</b> - 欢迎投稿至《眼见》栏目，分享你的见解
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://hosteye.net/chevereto-v4-zi-tuo-guan-tu-chuang-wan-quan-da-jian-jiao-cheng-wu-shi-pin-zhi-chi/"
                        >
                            <p>
                                <b>[Chevereto V4 自托管图床完全搭建教程]</b> - 我撰写的详细教程系列，手把手教你如何搭建自托管图床
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                想来看看吗？点击{' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://hosteye.net/"
                    >
                        这里
                    </a> 即可访问 HOSTEYE！
                </p>
            </div>
            <div className="text-block">
                <h2>HOSTEYE CHAT BOT</h2>
                <br />
                <p>
                HOSTEYE CHAT BOT 是一款利用AI技术驱动的智能聊天机器人，旨在通过自然语言对话，简化操作并提升用户体验。最初的设想是与鸡市的Telegram机器人集成，让用户可以浏览并自助上架商品，但后来转向通过GPT-4技术改进服务。用户无需复杂的菜单选择，只需通过简单的文字输入，便能完成交易咨询、商品上架等操作。
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={hosteyechatbot} />
                    <p style={styles.caption}>
                       
                            <sub>
                                <b>图 3: </b>在官网演示的HOSTEYE CHAT BOT
                            </sub>
                        </p>

                </div>
                <p>
                <br />
                主要功能包括：
                <br />
                1. 自然语言对话与交易中介：用户可以直接与机器人对话完成各种操作，如商品发布、交易查询等。机器人还能担任交易中介，保障交易过程的安全与顺畅。
                <br />
                2. 惠眼计划：通过该计划，用户的中介费用不仅能维持AI的运行，还能提供交易保险，在交易争议时给予保障。
                <br />
                3. 骗子档案系统：可以输入任意信息，来查询用户是否已经存在骗人的记录。
                <br />
                <br />
                目前该机器人已在HOSTEYE官网和Telegram平台上线，并将持续更新以优化用户体验。

                </p>
                <br />
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://hosteye.net/"
                        >
                            <p>
                                <b>[在官网使用HOSTEYE CHAT BOT]</b> - 你可以在网站右下角找到对话按钮！
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://t.me/HOSTEYE_CHAT_BOT"
                        >
                            <p>
                                <b>[在TG使用HOSTEYE CHAT BOT]</b> - 方便，简单，快捷
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                计划中的功能包括各种主机资讯的查询系统以及信用评级机制，进一步保障交易的安全性和可靠性。
                </p>
            </div>
            <div className="text-block">
                <h2>IMGEYE.COM</h2>
                <br />
                <p>
                IMGEYE 是我使用 Chevereto v4 搭建的公益图床服务，完全免费。用户可以在这个平台上上传图片和视频，享受便捷的存储和分享服务。尽管IMGEYE主要作为一个通用图床服务，但在确保不滥用的情况下，也允许适度上传成人内容。                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={imgeye} />
                    <p style={styles.caption}>
                       
                            <sub>
                                <b>图 4: </b>IMGEYE
                            </sub>
                        </p>

                </div>
                <br />
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://imgeye.com/"
                        >
                            <p>
                                <b>[IMGEYE]</b> - 请不要滥用公益图床服务
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                我致力于提供一个开放且高效的图床平台，支持各种类型的媒体文件上传，满足用户的多样化需求。为了维护平台的健康运营，我会对上传内容进行适当管理，确保服务的质量和合规性。
                </p>
            </div>
            <div className="text-block">
                <h2>FOXCOO EMBY</h2>
                <br />
                <p>
                我曾经建立了一个邀请制的 Emby 服务器，并成功扩展到一百位用户。这个服务器内容丰富，包括美剧、日剧、韩剧、综艺节目、纪录片、演唱会，甚至还有 R18 类别的内容。然而，使用的 Dropbox 无限盘服务在某个时刻被官方终止，这导致了庞大的存储费用变得难以承受。                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={foxcooemby} />
                    <p style={styles.caption}>
                        <sub>
                            <b>图 5:</b> 已经停止维护的FOXCOO EMBY
                        </sub>
                    </p>
                </div>
                <p>
                由于存储费用问题，我在2024年初不得不关闭了 FOXCOO EMBY 服务器。这对用户来说无疑是一次失望的消息，但由于成本压力，这个决定是我不得不做出的。希望在未来有机会能再次为大家提供更好的服务。
                </p>
                <br />
                <br />
                <p>
                   以上。
                </p>
            </div>
            <ResumeDownload />
        </div>
    );
};

const styles: StyleSheetCSS = {
    video: {
        width: '100%',
        padding: 12,
    },
    caption: {
        width: '80%',
    },
};

export default SoftwareProjects;
